.login_form_container {
  /* margin: 0px !important;
  background-color: rgb(146, 194, 197);
  width: 100%;
  padding: 40px; */

  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  /* margin: 60px; */
}

.login_form {
  display: flex;
  /* overflow: hidden; */
  text-align: center;
  justify-content: center;
  width: 400px;
  height: 400px;
  margin: auto;
  margin-top: 50px;
}

th {
  font-weight: 600;
}
td {
  font-weight: 500;
}

.form_container {
  background-size: 1099px;
  width: 100%;

  border-radius: 5px;
  border: 2px solid #c3e0e5;
}

.footer {
  width: 100%;
  background-color: rgb(35, 55, 107);
  color: white;
  display: flex;
  justify-content: center;
}

.footer-container {
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 3px 0px;
  width: 80%;
  max-width: 1280px;
  font-size: small;
}

#auth {
  width: 100%;
  border-radius: 5px;
  padding: 2px;
}

.title {
  margin-top: 0px;
  color: #095ad1;
  font-family: "Space Grotesk";
  /* font-size:25px */
  font-weight: 700;
}

.Homepage {
  width: 100%;
  height: 50px;
  margin-right: 0px !important;
  margin-left: 0px !important;
  padding: 12px;
  background-color: #fcfcfc;
  /* background-color: blue; */
}

.section1 {
  margin-right: 0px !important;
  margin-left: 0px !important;
  padding: 12px;
  background-color: #dcdcdc;
  /* background-color: blue; */
}

.Header1 {
  background-color: #d7fcd4;
  text-align: center;
  padding: 5px;
  margin-top: 5px;
  font-size: 18px;
  font-weight: 500;
}

.type {
  height: 15px;
  min-height: 15px;
}
