.table-scroll {
  overflow: auto;
  position: relative;
}

.table-scroll th,
.table-scroll td {
  white-space: nowrap;
  /* Prevent content from wrapping */
}

/* Pinned Columns */
.table-scroll th:nth-child(-n + 3),
.table-scroll td:nth-child(-n + 3) {
  /* position: sticky; */
  left: 0;
  z-index: 2;
  border-right: 0px solid #ddd;
  background-color: white;
}

.table-scroll th:nth-child(-n + 3) {
  background-color: #86deff;
}
.table-scroll th:nth-child(2) {
  background-color: #86deff;
}
.table-scroll th:nth-child(3) {
  background-color: #86deff;
}
.table-scroll th:nth-child(2),
.table-scroll td:nth-child(2) {
  left: 55px;
  width: 220px;
  border: 0px solid #ddd;
  z-index: 3;
}

.table-scroll th:nth-child(3),
.table-scroll td:nth-child(3) {
  left: 250px;
  border: 0px solid #ddd;
  z-index: 4;
}

/* Pinned Headers */
.table-scroll thead {
  display: block;
}

.table-scroll thead > tr {
  display: flex;
}

.table-scroll thead > th {
  flex: 0 0 auto;
}

.table-scroll thead > th:first-child {
  z-index: 5;
}





 

.login-link {
  position: relative;
  font-size: 18px;
  cursor: pointer;
}

.login-link::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 20%;
    /* Set the width to cover half of the text */
  height: 2px;
  background-color: transparent;
  /* Initial border color */
  transition: background-color 0.3s ease;
  /* Add transition for a smooth effect */
}

.login-link:hover::after {
  background-color: #3498db;
  /* Border color on hover */
}


.headerStyles{
      font-size: 16px;
        color: rgb(63, 31, 183);
        background-color: rgb(134, 222, 255);
}
 